import React from 'react'
import cx from 'classnames'
import './style.sass'

const Message = ({
	className,
	success,
	error,
	children,
	...props
}) => {
	const cs = cx('Message', className, {
		'Message--success': success,
		'Message--error': error,
	})

	return (
		<div className={cs} {...props}>{children}</div>
	)
}

export default Message