import Request from "./request";

const DATA_MAP_SERVER = "https://id.datamap.io";
const GET_VIMEO_OEMBED_URL = "https://vimeo.com/api/oembed.json";

export const getProjectCodeData = (code) => {
  const url = `${DATA_MAP_SERVER}/api/project/${code}`;

  return Request.get({
    url,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getVimeoOembed = (videoURL, doNotTrack = true) => {
  const urlParams = new URLSearchParams({
    url: videoURL,
    dnt: doNotTrack,
  });

  const url = `${GET_VIMEO_OEMBED_URL}?${urlParams.toString()}`;

  return Request.get({ url });
};

export const getYoutubeEmbed = (videoLink) => {
  const url = new URL(videoLink);
  const urlParams = new URLSearchParams(url.search);
  const videoURL = urlParams.get("v");

  if (videoURL) {
    return `https://www.youtube-nocookie.com/embed/${videoURL}`;
  }

  return "";
};
