import React, { Component } from 'react'

import Section from '../../common/Section/index'
import { InputButtonForm } from '../../common/Input'
import { Heart, Github, Twitter } from '../../icons'

import { subscribeToNewsletter } from '../../api'

import './style.sass'

class Footer extends Component {

	render() {
		return (
			<Section className="PosmoFooter">
				<div className="PosmoFooter__form">
					<p>Our Newsletter!</p>
					<InputButtonForm border buttonText="Submit" placeholder="Your email address" type="email" submit={subscribeToNewsletter} />
					<div className="PosmoFooter__company">
						<p>Made in Zurich and Belgrade with<Heart /></p>
						<p>Datamap AG, Am Wasser 59, 8049 Zürich</p>
						<p>© Datamap 2019 - {new Date().getFullYear()}</p>
					</div>
					<div className="PosmoFooter__links">
						<a href="https://twitter.com/datamapio"><Twitter /></a>
						<a href="https://github.com/datamapio"><Github /></a>
					</div>
				</div>
				<a className="twitter-timeline" href="https://twitter.com/datamapio" data-tweet-limit="1" data-width="399" >_</a>
			</Section>
		);
	}
}

export default Footer
