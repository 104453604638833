import React from 'react'
import cx from 'classnames'

import './style.sass'

const Section = React.memo((props) => {
	const cs = cx('PosmoSection', props.className)

	return (
		<section className={cs}>
			{props.children}
		</section>
	);
})

export default Section