import axios from 'axios'

const requestHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

const performRequest = async function (reqestData) {
	try {
		const response = await axios({
			...reqestData,
			headers: requestHeaders,
		})
		return response
	} catch (e) {
		return {
			error: 'Something went wrong!',
			status: e && e.response && e.response.status ? e.response.status : null,
		}
	}
}

const subscribe = async (type, email) => {
	const response = await performRequest({
		method: 'POST',
		url: 'https://id.datamap.io/api/subscribe',
		data: {
			provider: 'Posmo',
			email,
			type
		}
	})

	return response
}

export const subscribeToAnnouncements = subscribe.bind(null, 'announcement')
export const subscribeToNewsletter = subscribe.bind(null, 'newsletter')

/*
data: { firstName, lastName, subject, message, email }
*/
export const contactUs = async (data) => {
	const response = await performRequest({
		method: 'POST',
		url: 'https://id.datamap.io/api/contact',
		data,
	})

	return response
}
