import axios from "axios";

function errorsOrDefault(
  error,
  defaultErrorMessage = "Request failed for an unknown reason"
) {
  return error || defaultErrorMessage;
}

const request = async function (req, defaultMsg) {
  try {
    const response = await axios(req);
    return response;
  } catch (e) {
    console.log("e", e);
    return {
      error: errorsOrDefault(e, defaultMsg),
      status: e,
    };
  }
};

class Request {
  static get({
    params = {},
    url,
    headers = {},
    responseType,
    defaultMsg,
  } = {}) {
    return request(
      {
        method: "GET",
        url,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }

  static post({
    data = {},
    params = {},
    url,
    headers = {},
    responseType,
    defaultMsg,
  } = {}) {
    return request(
      {
        method: "POST",
        url,
        data,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }

  static put({
    data = {},
    params = {},
    url,
    headers = {},
    responseType,
    defaultMsg,
  } = {}) {
    return request(
      {
        method: "PUT",
        url,
        data,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }

  static patch({
    data = {},
    params = {},
    url,
    headers = {},
    responseType,
    defaultMsg,
  } = {}) {
    return request(
      {
        method: "PATCH",
        url,
        data,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }

  static delete({
    params = {},
    url,
    headers = {},
    responseType,
    defaultMsg,
  } = {}) {
    return request(
      {
        method: "DELETE",
        url,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }
}

export default Request;
