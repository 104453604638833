import React, { Component, Fragment } from 'react'
import Section from '../common/Section'
import Button from '../common/Button'
import { InputButtonForm } from '../common/Input'

import { subscribeToAnnouncements } from '../api'

import landing from '../img/landing-img.png'
import phones2 from '../img/2-phones.png'
import phones3 from '../img/3-phones.png'
import appStore from '../img/app-store.svg'
import googlePlay from '../img/google-play.svg'

import photo1 from '../img/1.jpg'
import photo2 from '../img/2.jpg'
import photo3 from '../img/3.jpg'
import photo4 from '../img/4.jpg'
import photo5 from '../img/5.jpg'
import photo6 from '../img/6.jpg'

import { IconColor, IconBlackWhite } from '../icons'

import './style.sass'

class Landing extends Component {
	render() {
		return (
			<Fragment>
				<div className="PosmoParalaxGroup">
					<div className="PosmoParalaxGroup__effect Paralax--strips"></div>
					<div className="PosmoParalaxGroup__effect Paralax--rect"></div>
				</div>
				<div className="Posmo__content">
					<Section>
						<div className="LandingSection LandingSection--header">
							<h1>A 360 Degree View on Mobility</h1>
                <p className="LandingSection__subtitle">Posmo is a mobility data platform to collect and manage all types of transport, as well as to analyze and visualize the use of space. We use advanced machine learning models to understand how people move around.</p>
                <p className="LandingSection__subtitle">The future of cities, suburban and rural areas is best built on data collected by people themselves. Every Posmo user can inspect, delete and download the personal data streamed via the app. And in a more general sense: we want people to be capable to understand and shape the circumstances in which they live.</p>
                <p className="LandingSection__subtitle">Our goal is a mobility data commons where ethics and privacy are not an afterthought.</p>
							<Button outsideAnchor href="https://id.datamap.io/signup?app=posmo-web">Get Started</Button>
						</div>
					</Section>
					<Section>
						<div className="LandingSection LandingSection--all-images">
							<img className="LandingSection__transport" src={landing} alt="Posmo transport" />
						</div>
						<div className="LandingSection LandingSection--images-swap">
							<div className="LandingSection__image-holder">
								<img className="LandingSection__transport LandingSection__single-img--first" src={photo1} alt="Posmo transport" />
								<img className="LandingSection__transport LandingSection__single-img" src={photo2} alt="Posmo transport" />
								<img className="LandingSection__transport LandingSection__single-img" src={photo3} alt="Posmo transport" />
								<img className="LandingSection__transport LandingSection__single-img" src={photo4} alt="Posmo transport" />
								<img className="LandingSection__transport LandingSection__single-img" src={photo5} alt="Posmo transport" />
								<img className="LandingSection__transport LandingSection__single-img" src={photo6} alt="Posmo transport" />
							</div>
						</div>
					</Section>
					<div className="AngledGradient">
						<Section>
							<div className="LandingSection LandingSection--with-image LandingSection--third">
								<div className="LandingSection__content">
									<IconColor />
									<h2>Posmo One<span className="Posmo__coming-soon">COMING SOON!</span></h2>
									<h3>Your movement diary</h3>
                  <p> Posmo One automatically records any walking, cycling, transit and car driving you do. The app is always on: no need to start and stop it. Just keep your phone in your pocket or your bag.</p>
                  <p> Be aware that the app consumes battery power.</p>
									<div className="NotifyForm">
										<p>Get notified when we launch!</p>
										<InputButtonForm buttonText="Submit" placeholder="Your email address" type="email" submit={subscribeToAnnouncements}/>
									</div>
								</div>
								<img className="LandingSection__phones" src={phones2} alt="Posmo app on phones" />
							</div>
						</Section>
					</div>
					<div className="AngledGradient AngledGradient--inverted">
						<Section>
							<div className="LandingSection LandingSection--with-image LandingSection--third">
								<img className="LandingSection__phones" src={phones3} alt="Posmo app on phones" />
								<div className="LandingSection__content">
									<IconBlackWhite />
									<h2>Posmo Segments</h2>
                  <h3>Segment Tracking and Labeling</h3>
                  <p>Posmo Segments measures single mode segments, e.g. your bus ride. It enables you, as well as cities, analysts and planners to compare different transport modes, – bike vs transit vs car –, and to analyze performance levels during the day, e.g. before and during the evening rush hour.</p>
                  <p> We rely on data collected and labeled with Posmo Segments as input for the machine learning needed for our Posmo One app.</p>
                  <p>Be aware that the app consumes battery power.</p>
                  <p>Together with the app, we offer a companion website to analyze and download your data.</p>
									<div className="LandingSection__links">
										<a href="https://itunes.apple.com/us/app/posmo-segments/id1450602777?mt=8">
											<img height="48" src={appStore} alt="App Store Link" />
										</a>
										<a href="https://play.google.com/store/apps/details?id=io.datamap.posmo_segments">
											<img height="48" src={googlePlay} alt="Google Play Link"></img>
										</a>
									</div>
								</div>
							</div>
						</Section>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default Landing;
