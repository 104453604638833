import React, { PureComponent } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";

import "./App.sass";
import cx from "classnames";

import Footer from "./common/Footer";

import Landing from "./Landing";
import Contact from "./Contact";
import Code from "./Code";
import { Logo } from "./icons";

const PosmoLogo = withRouter((props) => {
  const aboutPage = props.location.pathname.includes("about");
  const logoClass = cx("PosmoNavbar__logo", {
    "PosmoNavbar__logo--inverted": aboutPage,
  });

  return (
    <div className={logoClass}>
      <Logo />
    </div>
  );
});
class App extends PureComponent {
  state = {
    hamburger: false,
  };

  toggleMenu = () => this.setState({ hamburger: !this.state.hamburger });

  render() {
    const hamburger = cx("PosmoHamburger", {
      "PosmoHamburger--visible": this.state.hamburger,
    });
    const menu = cx("PosmoNavbar__links", {
      "PosmoNavbar__links--visible": this.state.hamburger,
    });

    const pathName = window.location.pathname;
    const isCodePage = pathName.includes("/code/");

    return (
      <Router>
        <Switch>
          <Route path="/code/*" component={Code} />
        </Switch>
        {!isCodePage && (
          <main className="Posmo">
            <nav className="PosmoNavbar">
              <PosmoLogo />
              <button onClick={this.toggleMenu} className={hamburger}>
                <span className="PosmoHamburger__icon">
                  <span className="PosmoHamburger__inner"></span>
                </span>
              </button>
              <div className={menu}>
                <div className="PosmoNavbar__links--common">
                  <NavLink exact activeClassName="PosmoNavbar--selected" to="/">
                    Home
                  </NavLink>
                  {/*<span className="PosmoNavbar__links--pill" to="/community">Community<small>SOON</small></span>*/}
                  {/* <NavLink activeClassName="PosmoNavbar--selected" to="/developers">Developers</NavLink> */}
                  {/*<a href="https://blog.datamap.io/">Blog</a> */}
                  {/* To be commented/uncommented when other 'about' items are added */}
                  <NavLink
                    activeClassName="PosmoNavbar--selected"
                    to="/about/contact"
                  >
                    About
                  </NavLink>
                  {/* <div className="PosmoNavbar__submenu">
									<NavLink className="PosmoNavbar__submenuMain" activeClassName="PosmoNavbar--selected" to="/about">About</NavLink>
									<div className="PosmoNavbar__itemHolder">
										<div className="PosmoNavbar__submenuItems">
											<NavLink className="PosmoNavbar__submenuItem" to="/about/vision">Our Vision</NavLink>
											<NavLink className="PosmoNavbar__submenuItem" to="/about/team">Our team</NavLink>
											<NavLink className="PosmoNavbar__submenuItem" to="/about/contact">Contact</NavLink>
										</div>
									</div>
								</div> */}
                </div>
                <div className="PosmoNavbar__links--user">
                  <a href="https://id.datamap.io/?app=posmo-web">Log In</a>
                  <a
                    className="PosmoNavbar__links--signup"
                    href="https://id.datamap.io/signup?app=posmo-web"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </nav>
            <article>
              <Switch>
                <Route exact path="/" component={Landing} />
                {/* <Route exact path="/about" component={() => <Redirect to="/about/vision" />}/> */}
                <Route path="/about/contact" component={Contact} />
              </Switch>
              <Footer />
            </article>
          </main>
        )}
      </Router>
    );
  }
}

export default App;
