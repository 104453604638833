import React, { Component, Fragment } from 'react'
import Section from '../common/Section'
import Button from '../common/Button'
import Message from '../common/Message'
import { Input, InputGroup } from '../common/Input'
import { Phone, Letter } from '../icons'
import { contactUs } from '../api'

import './style.sass'

class Contact extends Component {

	state = {
		errorMessage: '',
		successMessage: '',
		loading: false,
	}

	submit = async (e) => {
		e.preventDefault()
		this.setState({ loading: true })


		const info = {
			firstName: this.form.first_name.value,
			lastName: this.form.last_name.value,
			subject: this.form.subject.value,
			message: this.form.message.value,
			email: this.form.email.value,
		}

		const { data } = await contactUs(info)
		if (data && data.message === 'OK') {
			this.form.reset()
			this.setState({
				errorMessage: '',
				successMessage: "Success! We'll get back to you as soon as possible!",
				loading: false,
			})
			return
		}

		this.setState({
			successMessage: '',
			errorMessage: "Ooops, something went wrong!",
			loading: false,
		})
	}

	render() {
		return (
			<Fragment>
				<div className="ContactHeader">
					<Section>
							<h1>Contact</h1>
							<h3>+41 78 607 75 06</h3>
							<h3>Mon - Fri 9:00AM - 5PM CEST</h3>
							<Button outsideAnchor small className="ContactHeader__call" href="tel:+41786077506"><Phone/>Call Us</Button>
							<h3>Or fill out the form below, we'll quickly get back to you</h3>
					</Section>
				</div>
				<div className="AngledGradient AngledGradient--inverted">
					<Section>
						<form action="POST" className="ContactForm" onSubmit={this.submit} ref={(form) => this.form = form}>
							<label htmlFor="first_name">Name</label>
							<InputGroup>
								<Input border name="first_name" type="text" small placeholder="First Name" required/>
								<Input border name="last_name" type="text" small placeholder="Last Name" required/>
							</InputGroup>
							<label htmlFor="email">Email Address</label>
							<Input border name="email" type="email" placeholder="Email Address" required/>
							<label htmlFor="subject">Subject</label>
							<Input border name="subject" type="text" placeholder="Subject" required/>
							<label htmlFor="message">Subject</label>
							<textarea name="message" rows="5" required/>
							<Button className="ContactForm__submit" small type="submit" disabled={this.state.loading}><Letter/>Submit</Button>
							<div className="ContactForm__messages">
								{this.state.successMessage && <Message success>{this.state.successMessage}</Message>}
								{this.state.errorMessage && <Message error>{this.state.errorMessage}</Message>}
							</div>
						</form>
					</Section>
				</div>
			</Fragment>
		)
	}
}

export default Contact
