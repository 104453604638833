import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import './style.sass'

const Button = ({
	className,
	small,
	href,
	outsideAnchor,
	inputGroup,
	...props
}) => {
	const TypeOfLink = outsideAnchor ? 'a' : Link
	const Tag = href ? TypeOfLink : 'button'
	const hrefProp = href && outsideAnchor ? { href } : { to: href }

	const cs = cx('Button', className, {
		'Button--small': small,
		'Button--input-group': inputGroup
	})

	return (
		<Tag className={cs} {...hrefProp} {...props}>
			{props.children}
		</Tag>
	)
}

export default Button