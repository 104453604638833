import React from 'react'
import cx from 'classnames'

import Button from '../Button'
import Message from '../Message'

import './style.sass'

export const Input = React.forwardRef(({ small, className, placeholder, border, ...props }, ref) => {

	const cs = cx('Input', className, { 'Input--small': small, 'Input--border': border })

	return (
		<input
			ref={ref}
			className={cs}
			placeholder={placeholder}
			maxLength='255'
			{...props}
		/>
	)
})

export const InputGroup = (props) => {
	return (
		<div className="InputGroup">
			{props.children}
		</div>
	)
}

export class InputButtonForm extends React.Component {

	inputRef = React.createRef()

	state = {
		successMessage: '',
		errorMessage: '',
		disabled: false
	}

	submit = async (e) => {
		e.preventDefault()
		this.setState({
			successMessage: '',
			errorMessage: '',
			disabled: true,
		})

		const val = this.inputRef.current.value
		const res = await this.props.submit(val)
		if (res.data && res.data.message === 'OK') {
			this.setState({ 
				successMessage: 'Successfully subscribed!',
				disabled: false,
			})
		} else {
			this.setState({ 
				errorMessage: 'Something went wrong!',
				disabled: false
			})
		}
	}

	render() {
		const { inputGroup, small, submit, buttonText, ...inputProps } = this.props

		return (
			<div className="InputButton">
				<form action="POST" onSubmit={this.submit} className="InputButton__form">
					<Input {...inputProps} ref={this.inputRef} />
					<Button disabled={this.state.disabled} small inputGroup type="submit">{this.props.buttonText}</Button>
				</form>
				{this.state.successMessage && <Message success>{this.state.successMessage}</Message>}
				{this.state.errorMessage && <Message error>{this.state.errorMessage}</Message>}
			</div>
		)
	}
}