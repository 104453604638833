import React, { useEffect, useState } from "react";

import { getProjectCodeData, getVimeoOembed, getYoutubeEmbed } from "./api";

import styles from "./styles.module.scss";
import cx from "classnames";

const ProjectCodePage = () => {
  const [project, setProject] = useState({});
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [vimeoIframe, setVimeoIframe] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [imageLink, setImageLink] = useState("");

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    const indexOfProjectCode = window.location.pathname.lastIndexOf("code/");
    const code = window.location.pathname.substring(indexOfProjectCode + 5);

    const res = await getProjectCodeData(code);
    if (res.data && res.data.data) {
      setProject(res.data.data);
      fetchMediaLink(res.data.data);
    }
  };

  const fetchMediaLink = async (project) => {
    if (project.media_type === "video") {
      if (project.media && project.media.includes("vimeo.com")) {
        const res = await getVimeoOembed(project.media);
        if (res.data) {
          setVimeoIframe(res.data.html);
        }
      } else if (project.media && project.media.includes("youtube.com")) {
        const ytLink = getYoutubeEmbed(project.media);
        if (ytLink) {
          setYoutubeLink(ytLink);
        }
      }
    }

    if (project.media_type === "image") {
      setImageLink(project.media);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const copyCode = (e) => {
    navigator.clipboard.writeText(project.code);
  };

  if (!project.description) {
    return <div>Loading...</div>;
  }

  const mainTitle = project.title;
  const mainLead = project.project_lead;
  const mainDescription = project.description.replaceAll("\n", "\n\n");
  const posmoTitle = project.posmo_title;
  const posmoDescriiption = project.posmo_description;
  // const mediaLink = getVimeoPlayerLink()

  const headerContentCSS = cx(styles.ProjectCodePage__header, {
    [styles.ProjectCodePage__headerMobileOpen]: isMenuOpened,
  });

  return (
    <>
      <main className={styles.ProjectCodePage}>
        <div className={styles.ProjectCodePage__logo}>
          <img src={project.logo} alt="logo" />
        </div>
        <div className={styles.ProjectCodePage__content}>
          <div className={styles.ProjectCodePage__left} />
          <div className={styles.ProjectCodePage__right} />

          <section className={styles.ProjectCodePage__headerSection}>
            <h2 className={styles.ProjectCodePage__h2}>{mainTitle}</h2>
            <p>{mainLead}</p>
          </section>

          {!!vimeoIframe && (
            <section className={styles.ProjectCodePage__videoSection}>
              <div className={styles.ProjectCodePage__video}>
                <div dangerouslySetInnerHTML={{ __html: vimeoIframe }} />
              </div>
            </section>
          )}

          {!!youtubeLink && (
            <section className={styles.ProjectCodePage__videoSection}>
              <div className={styles.ProjectCodePage__video}>
                <iframe
                  src={youtubeLink}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </section>
          )}

          {!!imageLink && (
            <section className={styles.ProjectCodePage__imageMediaSection}>
              <img src={imageLink} alt="Img" />
            </section>
          )}

          <section className={styles.ProjectCodePage__contentSection}>
            <p>{mainDescription}</p>
          </section>

          <section
            className={cx(
              styles.ProjectCodePage__contentSection,
              styles.ProjectCodePage__lastContentSection
            )}
          >
            <h2>Mit der Posmo Project App Daten sammeln </h2>
            <p className={styles.ProjectCodePage__paragraph}>
              Die Posmo Project App analysiert dein Mobilitätsverhalten
              automatisch: Über GPS- und andere Sensordaten und dank
              maschinellen Lernens kann die App interpretieren, ob du zu Fuss
              unterwegs bist, Velo oder Auto fährst, oder im ÖV sitzt. Die App
              läuft immer im Hintergrund. Du musst sie nicht aus-oder
              einschalten.
            </p>
            <h2 className={styles.ProjectCodePage__titleLast}>{posmoTitle}</h2>
            <p>{posmoDescriiption}</p>
          </section>
        </div>
      </main>
    </>
  );
};

export default ProjectCodePage;
